// 申遗承诺
const selfData = {
    loading: false,
    snTable: {

        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备",
                prop: "SBMC",
            }, {
                label: "时间",
                prop: "JCSJ",
            }, {
                label: "风向",
                prop: "FX",
            }, {
                label: "风速(m/s)",
                prop: "FS",
            }, {
                label: "雨量(mm)",
                prop: "YL",
            }, {
                label: "气温(℃)",
                prop: "WD",
            }, {
                label: "相对湿度(%)",
                prop: "SD",
            }, {
                label: "气压(Pa)",
                prop: "QY",
            }, {
                label: "太阳辐射(mwh/cm²)",
                prop: "TYFS",
            },
            {
                prop: 'SHZT',
                label: '数据状态',
                minWidth: 80,
                transList: {
                    0: "已提交",
                    1: "未提交"
                }
            }],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;